import React from 'react'
import { Card } from 'antd'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import variablePie from 'highcharts/modules/variable-pie.js'
import { EXCLUDE_MENU_YEAR } from '../../utils/config'
import { Empty } from 'antd'

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
  variablePie(Highcharts)
}

const options = {
  chart: {
    type: 'variablepie',
    margin: [0, 0, 0, 0],
    events: {
      redraw: function () {
        fadeBorder(this)
      },
      load: function () {
        fadeBorder(this)
      },
    },
    height: 337,
    // marginBottom: -20,
  },
  exporting: {
    enabled: false,
  },
  colors: ['#ff5a5a', '#37E30B'],
  title: {
    text:
      "<span style='font-size: 28px;font-weight: bold;font-family: Mitr'>75 %</span><br><span style='font-family: Mitr;'>success</span>",
    align: 'center',
    verticalAlign: 'middle',
    y: 40,
  },
  legend: false,
  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      size: 185,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      minPointSize: 10,
      innerSize: '70%',
      zMin: 0,
      name: '',
      data: [
        {
          name: 'ทำไม่เสร็จ',
          y: 30,
          z: 100,
        },
        {
          name: 'ทำเสร็จ',
          y: 70,
          z: 100,
        },
      ],
    },
  ],
}

const MyCard = ({
  title,
  complete,
  incomplete,
  completeText,
  incompleteText,
  isLoading,
  unit,
  mkey,
  system,
  year,
  semester,
}) => {
  const fadeBorder = (target) => {
    if (target._borderCircle) target._borderCircle.destroy()
    let height = target.plotHeight + 80
    const border = target.renderer
      .circle(
        target.chartWidth / 2,
        height / 2 + target.plotTop - 40,
        height / 4,
      )
      .attr({
        fill: 'rgba(0,0,0,0)',
        stroke: '#37E30B',
        left: -100,
        top: -20,
        'stroke-width': 1,
      })
      .add()
    target._borderCircle = border
  }

  const update = () => {
    try {
      let total = complete + incomplete
      let success = (complete * 100) / total
      let option = JSON.parse(JSON.stringify(options))
      option.series[0].data[0].name = incompleteText || 'ทำไม่เสร็จ'
      option.series[0].data[1].name = completeText || 'ทำเสร็จ'
      option.title.text = `<span style='font-size: 28px;font-weight: bold;font-family: Mitr'>${
        isLoading
          ? 'loading'
          : isNaN(success)
          ? '0'
          : Math.floor(success) +
            " %</span><br><span style='font-family: Mitr;'>success</span>"
      } `
      option.series[0].name = title
      option.series[0].data[0].y = Number(
        ((incomplete * 100) / total).toFixed(2),
      )
      option.series[0].data[1].y = Number(success.toFixed(2))
      option.chart.events = {
        redraw: function () {
          fadeBorder(this)
        },
        load: function () {
          fadeBorder(this)
        },
      }
      option.tooltip = {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          'ร้อยละ : <b>{point.y}</b><br/>',
      }
      return option
    } catch (e) {
      console.log(e)
    }
  }

  const renderFooter = (color, title, value) => {
    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            backgroundColor: color,
            width: 10,
            height: 10,
            display: 'inline-block',
            alignSelf: 'center',
            borderRadius: 2,
          }}
        ></div>
        <div
          style={{
            flex: 1,
            display: 'inline-block',
            marginLeft: 10,
            fontSize: 14,
            color: 'grey',
            fontWeight: 'normal',
            borderRadius: 2,
          }}
        >
          {title}
        </div>
        <div
          style={{
            float: 'right',
            display: 'inline-block',
            marginLeft: 10,
            fontSize: 14,
            fontWeight: 'normal',
          }}
        >
          {value && value.formatMoney(0)}
        </div>
      </div>
    )
  }

  const key = `/progress/${mkey}/${system}/${year}/${semester}`
  const isShow = EXCLUDE_MENU_YEAR.indexOf(key) === -1

  return isShow ? (
    <Card
      bordered={false}
      style={{ marginRight: 15, marginBottom: 15, height: '430px' }}
    >
      <HighchartsReact highcharts={Highcharts} options={update()} />
      <div style={{ position: 'absolute', top: 30, left: 20, fontSize: 18 }}>
        <div>{title || ''}</div>
        <div style={{ fontStyle: 'italic', fontSize: 12, color: 'grey' }}>
          {`ทั้งหมด  ${(complete + incomplete).formatMoney(0)} ${unit || ''}`}
        </div>
      </div>
      {renderFooter('#37E30B', completeText || 'ทำเสร็จ', complete)}
      {renderFooter('#ff5a5a', incompleteText || 'ทำไม่เสร็จ', incomplete)}
    </Card>
  ) : (
    <Card
      bordered={false}
      style={{ marginRight: 15, marginBottom: 15, height: '430px' }}
    >
      <div style={{ position: 'absolute', top: 30, left: 20, fontSize: 18 }}>
        <div>{title || ''}</div>
      </div>
      <div style={{ marginTop: 130 }}>
        <Empty description={'ในภาคเรียนนี้ไม่มีการทำข้อมูลในส่วนนี้'} />
      </div>
    </Card>
  )
}

export default MyCard
