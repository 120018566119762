import JSZip from 'jszip'
import { useEffect, useState } from 'react'

const GetFileBlobUsingURL = (url, convertBlob) => {
  let xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.setRequestHeader('authorization', `JWT ${sessionStorage.getItem('t')}`)
  xhr.addEventListener('load', () => {
    convertBlob(xhr.response)
  })
  xhr.send()
}

const GetFileObjectFromURL = (filePathOrUrl, convertBlob) => {
  GetFileBlobUsingURL(filePathOrUrl, (blob) => {
    convertBlob(blob)
  })
}

export const useZip = (name) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      name &&
      name.indexOf('undefined') === -1
    ) {
      setData(null)
      GetFileObjectFromURL(`/api/compress/${name}`, function (fileObject) {
        new JSZip.external.Promise(function (resolve, reject) {
          resolve(fileObject)
        })
          .then(function (data) {
            return JSZip.loadAsync(data)
          })
          .then((zip) => {
            return zip.file('data.json').async('string')
          })
          .then((str) => {
            setData(JSON.parse(str))
          })
          .catch((e) => {
            setData([])
          })
      })
    }
  }, [name])

  return data
}

export const useZipWithTime = (name) => {
  const df = { data: null, timestamp: '' }
  const [data, setData] = useState({ ...df })
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      name &&
      name.indexOf('undefined') === -1
    ) {
      setData({ ...df })
      GetFileObjectFromURL(`/api/compress/${name}`, function (fileObject) {
        new JSZip.external.Promise(function (resolve, reject) {
          resolve(fileObject)
        })
          .then(function (data) {
            return JSZip.loadAsync(data)
          })
          .then(async (zip) => {
            return {
              data: await zip.file('data.json').async('string'),
              timestamp: zip?.files['data.json']?.date,
            }
          })
          .then((obj) => {
            setData({ data: JSON.parse(obj.data), timestamp: obj.timestamp })
          })
          .catch((e) => {
            setData({ data:[], timestamp: '' })
          })
      })
    }
  }, [name])
  return data
}

export const fetchZip = (name) => {
  return new Promise((resolve) => {
    GetFileObjectFromURL(`/api/compress/${name}`, function (fileObject) {
      new JSZip.external.Promise(function (resolve, reject) {
        resolve(fileObject)
      })
        .then(function (data) {
          return JSZip.loadAsync(data)
        })
        .then((zip) => {
          return zip.file('data.json').async('string')
        })
        .then((str) => {
          resolve(JSON.parse(str))
        })
        .catch((e) => {
          resolve([])
        })
    })
  })
}
