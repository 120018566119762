import { fetchZip } from '../../components/useZip'

export const progress01 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress01_summary_${reverse ? 'asc' : 'desc'}`)
}

export const progress05 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress05_summary_${reverse ? 'asc' : 'desc'}`)
}

export const progress06 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress06_summary_1_${reverse ? 'asc' : 'desc'}`)
}

export const progress062 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress06_summary_2_${reverse ? 'asc' : 'desc'}`)
}

export const progress063 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress06_summary_3_${reverse ? 'asc' : 'desc'}`)
}

export const progressReport = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress_report_summary_1_${reverse ? 'asc' : 'desc'}`)
}

export const progressReport2 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress_report_summary_2_${reverse ? 'asc' : 'desc'}`)
}

export const progressReport3 = ({ system, year, semester, reverse }) => {
  return fetchZip(`monitor_${system}_${year}_${semester}_progress_report_summary_3_${reverse ? 'asc' : 'desc'}`)
}

export const progress001 = ({ system, year, semester, reverse }) => {
  const aggregate = [
    {
      $match: {
        $and: [
          {
            application: system,
            year: Number(year),
            semester: Number(semester),
          },
        ],
      },
    },
    {
      $group: {
        _id: {
          area: '$areaid',
          areaname: '$area',
        },
        count: { $sum: 1 },
        done: {
          $sum: { $cond: [{ $eq: ['$k001_status', 'บันทึกแล้ว'] }, 1, 0] },
        },
      },
    },
    {
      $group: {
        _id: null,
        count: { $sum: '$count' },
        done: { $sum: '$done' },
        list: {
          $addToSet: {
            _id: '$_id',
            count: '$count',
            done: '$done',
            ratio: { $divide: ['$done', '$count'] },
          },
        },
      },
    },
    { $unwind: '$list' },
    { $sort: { 'list.ratio': reverse ? 1 : -1 } },
    { $limit: 10 },
  ]
  return new Promise(async (resolve, reject) => {
    let res = await fetch(`/api/obec/kor001_monitor/aggregate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `JWT ${sessionStorage.getItem('t')}`
      },
      body: JSON.stringify(aggregate),
    })
    res = await res.json()
    let result = { count: 0, done: 0, data: [] }
    res.forEach((d) => {
      let x = d.list
      result.count = d.count
      result.done = d.done
      result.data.push({
        areaid: x._id.area,
        areaname: x._id.areaname,
        count: x.count,
        done: x.done,
      })
    })
    resolve(result)
  })
}

export const progress002 = ({ system, year, semester, reverse }) => {
  const aggregate = [
    {
      $match: {
        $and: [
          {
            application: system,
            year: Number(year),
            semester: Number(semester),
          },
        ],
      },
    },
    {
      $group: {
        _id: {
          area: '$areaid',
          areaname: '$area',
        },
        count: { $sum: 1 },
        done: {
          $sum: { $cond: [{ $eq: ['$k002_status', 'บันทึกแล้ว'] }, 1, 0] },
        },
      },
    },
    {
      $group: {
        _id: null,
        count: { $sum: '$count' },
        done: { $sum: '$done' },
        list: {
          $addToSet: {
            _id: '$_id',
            count: '$count',
            done: '$done',
            ratio: { $divide: ['$done', '$count'] },
          },
        },
      },
    },
    { $unwind: '$list' },
    { $sort: { 'list.ratio': reverse ? 1 : -1 } },
    { $limit: 10 },
  ]
  return new Promise(async (resolve, reject) => {
    let res = await fetch(`/api/obec/kor002_monitor/aggregate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `JWT ${sessionStorage.getItem('t')}`
      },
      body: JSON.stringify(aggregate),
    })
    res = await res.json()
    let result = { count: 0, done: 0, data: [] }
    res.forEach((d) => {
      let x = d.list
      result.count = d.count
      result.done = d.done
      result.data.push({
        areaid: x._id.area,
        areaname: x._id.areaname,
        count: x.count,
        done: x.done,
      })
    })
    resolve(result)
  })
}

export const progressRefund = ({ system, year, semester, reverse }) => {
  return new Promise(async (resolve, reject) => {
    const query = {
      application: system,
      year: (Number(year) + 543).toString(),
      semester: semester,
    }
    let res = await fetch(`/api/obec/refundmoney/query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `JWT ${sessionStorage.getItem('t')}`
      },
      body: JSON.stringify({ query, limit: 30000 }),
    })
    res = await res.json()
    let data = []
    res.forEach((x) => {
      x.student_count = Object.keys(x?.student || {}).length || 0
      data.push({ _source: x })
    })
    let result = { count: 0, done: 0, data: [] }
    let group = []
    data.forEach((x) => {
      const count = 1
      const done = x._source.receive === true
      const index = group.findIndex((y) => y.areaid === x._source.areaid)
      result.count += count
      result.done += done
      if (index > -1) {
        group[index].count += count
        group[index].done += done
      } else {
        group.push({
          areaid: x._source.areaid,
          areaname: x._source.areaname,
          count: count,
          done: count,
        })
      }
    })
    data = []
    result.data = group.sort((a, b) => {
      let val1 = b.done === 0 ? 0 : b.done / b.count
      let val2 = a.done === 0 ? 0 : a.done / a.count
      return val1 - val2
    })
    if (reverse) result.data = result.data.reverse()
    result.data = result.data.slice(0, 10)
    resolve(result)
  })
}

const LoadData = () => null
export default LoadData
