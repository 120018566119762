import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Space,
  Menu,
  Dropdown,
  Form,
  Row,
  Col,
  Table,
  Skeleton,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import MyCard from './MyCard'
import Image from 'next/image'
import { SYSTEMS } from '../../utils/config'
import { YEAR_SEMESTER } from '../../utils/config'
import * as LOAD from './LoadData'

const defaultData = [
  { title: 'นร.01/กสศ.01', complete: 0, incomplete: 0, isLoading: true, mkey: 'form01' },
  { title: 'นร.05/กสศ.05', complete: 0, incomplete: 0, isLoading: true, mkey: 'form05' },
  {
    title: 'แบบรายงานเงินอุดหนุน กลุ่มต่อเนื่อง',
    complete: 0,
    incomplete: 0,
    isLoading: true,
    mkey: 'report'
  },
  {
    title: 'แบบรายงานเงินอุดหนุน กลุ่มใหม่รอบ 1',
    complete: 0,
    incomplete: 0,
    isLoading: true,
    mkey: 'report'
  },
  {
    title: 'แบบรายงานเงินอุดหนุน กลุ่มใหม่รอบ 2',
    complete: 0,
    incomplete: 0,
    isLoading: true,
    mkey: 'report'
  },
  { title: 'ก.001', complete: 0, incomplete: 0, isLoading: true, mkey: 'form001' },
  { title: 'ก.002', complete: 0, incomplete: 0, isLoading: true, mkey: 'form002' },
  {
    title: 'คืนเงิน กสศ.',
    complete: 0,
    incomplete: 0,
    completeText: 'จำนวนใบเสร็จที่ออกได้',
    incompleteText: 'จำนวนใบเสร็จที่ออกไม่ได้',
    isLoading: true,
    mkey: 'refund'
  },
]
const system = SYSTEMS.reduce((a, v) => ({ ...a, [v.system]: v.title }), {})

function Home(params) {
  const [sys, setSys] = useState('cct')
  const [ys, setYS] = useState(Object.keys(YEAR_SEMESTER)[0])
  const [selected, setSelected] = useState('นร.01/กสศ.01')
  const [reverse, setReverse] = useState(false)
  const [data, setData] = useState(JSON.parse(JSON.stringify(defaultData)))
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loading = data.filter((x) => x.isLoading === true).length
    const count = Object.keys(data).length
    if (loading === count) return
    let tmp = JSON.parse(JSON.stringify(defaultData))
    const year = ys.split('/')[1]
    if (Number(year) < 2564 || sys === 'cct-onab') {
      tmp[2].title = 'นร.06/กสศ.06 กลุ่มต่อเนื่อง'
      tmp[3].title = 'นร.06/กสศ.06 กลุ่มใหม่รอบ 1'
      tmp[4].title = 'นร.06/กสศ.06 กลุ่มใหม่รอบ 2'
    } else {
      tmp[2].title = 'แบบรายงานเงินอุดหนุน กลุ่มต่อเนื่อง'
      tmp[3].title = 'แบบรายงานเงินอุดหนุน กลุ่มใหม่รอบ 1'
      tmp[4].title = 'แบบรายงานเงินอุดหนุน กลุ่มใหม่รอบ 2'
    }
    setData(tmp)
  }, [ys, sys, reverse])

  useEffect(() => {
    const done = data.filter((x) => x.isLoading !== true).length
    if (done === 0) return loadData()
    const count = Object.keys(data).length
    if (count === done) setIsLoading(false)
    else setIsLoading(true)
  }, [data])

  const getParams = () => {
    let [semester, year] = ys.split('/')
    year = Number(year) - 543
    return { system: sys, year, semester, reverse }
  }

  const loadData = () => {
    const defaultData = {
      done: 0,
      count: 0,
      data: [],
    }
    const params = getParams()
    LOAD.progress01(params).then((res) => {
      if (res?.length === 0) res = defaultData
      data[0].complete = res.done
      data[0].incomplete = res.count - res.done
      data[0].data = res.data
      data[0].isLoading = false
      data[0].unit = 'คน'
      setData([...data])
    })
    LOAD.progress05(params).then((res) => {
      if (res?.length === 0) res = defaultData
      data[1].complete = res.done
      data[1].incomplete = res.count - res.done
      data[1].data = res.data
      data[1].isLoading = false
      data[1].unit = 'โรงเรียน'
      setData([...data])
    })
    if (params.year < 2021 || sys === 'cct-onab') {
      LOAD.progress06(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[2].complete = res.done
        data[2].incomplete = res.count - res.done
        data[2].data = res.data
        data[2].isLoading = false
        data[2].unit = sys === 'cct-onab' ? 'วัด' : 'คน'
        setData([...data])
      })
      LOAD.progress062(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[3].complete = res.done
        data[3].incomplete = res.count - res.done
        data[3].data = res.data
        data[3].isLoading = false
        data[3].unit = sys === 'cct-onab' ? 'วัด' : 'คน'
        setData([...data])
      })
      LOAD.progress063(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[4].complete = res.done
        data[4].incomplete = res.count - res.done
        data[4].data = res.data
        data[4].isLoading = false
        data[4].unit = sys === 'cct-onab' ? 'วัด' : 'คน'
        setData([...data])
      })
    } else {
      LOAD.progressReport(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[2].complete = res.done
        data[2].incomplete = res.count - res.done
        data[2].data = res.data
        data[2].isLoading = false
        data[2].unit = 'โรงเรียน'
        setData([...data])
      })

      LOAD.progressReport2(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[3].complete = res.done
        data[3].incomplete = res.count - res.done
        data[3].data = res.data
        data[3].isLoading = false
        data[3].unit = 'โรงเรียน'
        setData([...data])
      })

      LOAD.progressReport3(params).then((res) => {
        if (res?.length === 0) res = defaultData
        data[4].complete = res.done
        data[4].incomplete = res.count - res.done
        data[4].data = res.data
        data[4].isLoading = false
        data[4].unit = 'โรงเรียน'
        setData([...data])
      })
    }
    LOAD.progress001(params).then((res) => {
      if (res?.length === 0) res = defaultData
      data[5].complete = res.done
      data[5].incomplete = res.count - res.done
      data[5].data = res.data
      data[5].isLoading = false
      data[5].unit = 'โรงเรียน'
      setData([...data])
    })
    LOAD.progress002(params).then((res) => {
      if (res?.length === 0) res = defaultData
      data[6].complete = res.done
      data[6].incomplete = res.count - res.done
      data[6].data = res.data
      data[6].isLoading = false
      data[6].unit = 'โรงเรียน'
      setData([...data])
    })
    LOAD.progressRefund(params).then((res) => {
      if (res?.length === 0) res = defaultData
      data[7].complete = res.done
      data[7].incomplete = res.count - res.done
      data[7].data = res.data
      data[7].isLoading = false
      data[7].unit = 'ใบ'
      setData([...data])
    })
  }

  const rankBlock = (rank, { areaname, count, done, perc }) => {
    rank = rank - 1
    let img = [
      '/images/1st-place.png',
      '/images/2nd-place.png',
      '/images/3rd-place.png',
    ][rank]
    let size = [80, 65, 50][rank]
    let height = [200, 160, 126][rank]
    return (
      <Col span={8} style={{ textAlign: 'center' }}>
        <Image src={img} alt="logo" width={size} height={size} />
        <div>{areaname}</div>
        <Card
          style={{
            height: height,
            margin: 5,
            backgroundColor: '#ffc700',
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: 22 }}>{perc}</div>
          <div style={{ display: 'flex', textAlign: 'left' }}>
            <span style={{ flex: 1 }}>ทำเสร็จ</span>
            <span>{done}</span>
          </div>
          <div style={{ display: 'flex', textAlign: 'left' }}>
            <span style={{ flex: 1 }}>ทั้งหมด</span>
            <span>{count}</span>
          </div>
        </Card>
      </Col>
    )
  }

  const renderRank = () => {
    const index = data.findIndex((x) => x.title === selected)
    const list = data[index]?.data || []
    const dataSource = list.map((x, i) => ({
      key: i,
      areaname: x.areaname,
      done: x.done.formatMoney(0),
      count: x.count.formatMoney(0),
      perc: (x.done === 0 ? 0 : ((x.done / x.count) * 100).toFixed(2)) + ' %',
    }))
    const columns = [
      {
        title: 'หน่วยกำกับ',
        dataIndex: 'areaname',
        key: 'areaname',
        // render: (text) => <Link>{text}</Link>,
        sorter: (a, b) => a.areaname.localeCompare(b.areaname),
      },
      {
        title:
          selected === 'คืนเงิน กสศ.' ? 'จำนวนรอบที่บันทึก' : 'จำนวนทั้งหมด',
        dataIndex: 'count',
        key: 'count',
        sorter: (a, b) => a.count - b.count,
      },
      {
        title:
          selected === 'คืนเงิน กสศ.' ? 'จำนวนใบเสร็จที่ออกได้' : 'ทำเสร็จ',
        dataIndex: 'done',
        key: 'done',
        sorter: (a, b) => a.done - b.done,
      },
      {
        title: 'คิดเป็น',
        dataIndex: 'perc',
        key: 'perc',
        sorter: (a, b) => a.perc - b.perc,
      },
    ]

    return (
      <Card
        bordered={false}
        title={
          <div style={{ display: 'flex' }}>
            <Image
              src="/images/ranking.png"
              alt="logo"
              width="25"
              height="25"
            />

            <div style={{ fontSize: 20, marginLeft: 10, flex: 1 }}>
              <Dropdown
                overlay={
                  <Menu onClick={(item) => setReverse(item.key === 'true')}>
                    <Menu.Item key={false}>TOP 10 Best Area</Menu.Item>
                    <Menu.Item key={true}>TOP 10 Worst Area</Menu.Item>
                  </Menu>
                }
              >
                <Button shape="round">
                  {reverse ? 'TOP 10 Worst Area' : 'TOP 10 Best Area'}{' '}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
            <span style={{ flex: 1 }}></span>
            <Dropdown
              overlay={
                <Menu onClick={(item) => setSelected(item.key)}>
                  {data.map((d, i) => (
                    <Menu.Item key={d.title}>{d.title}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button shape="round">
                {selected} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        }
      >
        {isLoading && (
          <>
            <Space style={{ justifyContent: 'center' }}>
              <Skeleton.Avatar active size={'large'} />
              <Skeleton.Avatar active size={'large'} />
              <Skeleton.Avatar active size={'large'} />
            </Space>
            <Skeleton active />
          </>
        )}
        {!isLoading && (
          <Row justify="center" align="bottom">
            {rankBlock(2, dataSource[1] || {})}
            {rankBlock(1, dataSource[0] || {})}
            {rankBlock(3, dataSource[2] || {})}
            <Table
              dataSource={dataSource.slice(3, 13)}
              columns={columns}
              bordered={true}
              style={{ width: '100%' }}
              pagination={false}
            />
          </Row>
        )}
      </Card>
    )
  }

  const qparams = getParams()

  return (
    <>
      <Form form={form} name="control-hooks" onFinish={() => {}}>
        <Form.Item>
          <Space direction="horizontal">
            <Dropdown
              overlay={
                <Menu onClick={(item) => setYS(item.key)}>
                  {Object.keys(YEAR_SEMESTER).map((key) => (
                    <Menu.Item key={key}>{YEAR_SEMESTER[key]}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button shape="round">
                {`${YEAR_SEMESTER[ys]}`} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown
              overlay={
                <Menu onClick={(item) => setSys(item.key)}>
                  {Object.keys(system).map((key) => (
                    <Menu.Item key={key}>{system[key]}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button shape="round">
                {system[sys]} <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Form.Item>
      </Form>

      <Row justify="center" align="top">
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
          <Row justify="start" align="top">
            {data.slice(0, 2).map((d, i) => (
              <Col key={i} xs={24} sm={24} md={24} lg={12} xl={12}>
                <MyCard {...d} {...qparams} />
              </Col>
            ))}
          </Row>
          <Row justify="start" align="top">
            {data.slice(2, 5).map((d, i) => (
              <Col key={i} xs={24} sm={24} md={24} lg={8} xl={8}>
                <MyCard {...d} {...qparams} />
              </Col>
            ))}
          </Row>
          <Row justify="start" align="top">
            {data.slice(5, 8).map((d, i) => (
              <Col key={i} xs={24} sm={24} md={24} lg={8} xl={8}>
                <MyCard {...d} {...qparams} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
          {renderRank()}
        </Col>
      </Row>
    </>
  )
}

export default Home
